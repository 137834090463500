import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { graphql, StaticQuery } from 'gatsby';
import { ReactComponent as Quotes } from '../assets/Quotes.svg';
import { ReactComponent as Ellipse } from '../assets/Ellipse.svg';
import { css } from '@emotion/react';
import Img, { FluidObject } from 'gatsby-image';
import { GetTestimonialsDataQuery } from '../graphql-types';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import ClampLines from 'react-clamp-lines';

interface TestimonialsCompProps {
  data: GetTestimonialsDataQuery;
  withoutHeader?: boolean;
  style?: React.CSSProperties;
  className?: string;
  revertColor?: boolean;
}

interface TestimonialsContent {
  message?: any;
  name?: string;
  position?: string;
  companyLogo?: FluidObject;
  photo?: FluidObject;
  companyLink?: string;
}

const TestimonialsComp: React.FC<TestimonialsCompProps> = ({
  data,
  withoutHeader,
  children,
  style,
  className,
  revertColor,
}) => {
  let timeout!: NodeJS.Timeout;
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => autoSlide(), [currentIndex]);

  function autoSlide() {
    timeout = setTimeout(() => {
      if (currentIndex === contents.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 10000);
    return () => clearTimeout(timeout);
  }

  function left() {
    const idx = currentIndex || 0;
    if (idx < 1) {
      return;
    }
    setCurrentIndex(idx - 1);
    clearTimeout(timeout);
  }

  function right() {
    const idx = currentIndex || 0;
    if (idx > 6) {
      return;
    }
    setCurrentIndex(idx + 1);
    clearTimeout(timeout);
  }

  const andika = data?.andika?.childrenImageSharp[0]?.fluid;
  const mignonne = data?.mignonne?.childrenImageSharp[0]?.fluid;
  const andi = data?.andi?.childrenImageSharp[0]?.fluid;
  const ivan = data?.ivan?.childrenImageSharp[0]?.fluid;
  const sejutaCita = data?.sejutaCita?.childrenImageSharp[0]?.fluid;
  const akseleran = data?.akseleran?.childrenImageSharp[0]?.fluid;
  const dauresia = data?.dauresia?.childrenImageSharp[0]?.fluid;
  const tag = data?.tag?.childrenImageSharp[0]?.fluid;

  const contents: TestimonialsContent[] = [
    {
      message:
        'Sangat profesional, cepat tanggap, dan yang terpenting trustworthy serta bertanggung jawab. Saya sudah merekomendasikan Easybiz ke tiga orang teman dan tidak akan ragu untuk terus mempercayakan kebutuhan administrasi legal kepada Easybiz',
      name: 'Andika Sudarman',
      position: 'CEO, Sejuta Cita',
      companyLogo: sejutaCita,
      photo: andika,
      companyLink: 'https://sejutacita.id/',
    },
    {
      message:
        'Easybiz sangat memperhatikan kami mulai dari menentukan kategori bisnis - mengingat bisnis kami tergolong baru - hingga mau memahami motivasi saya mendirikan perusahaan ini. Menurut saya, hal-hal seperti itu penting diketahui sehingga ketika melayani customer bisa dilakukan dengan baik dan benar',
      name: 'Mignonne N.B. Maramis',
      position: 'DaurEsia',
      companyLogo: dauresia,
      photo: mignonne,
      companyLink: 'http://www.dauresia.com/id/',
    },
    {
      message:
        'Easybiz sangat membantu saya untuk mendirikan perusahaan, semua keperluan dan proses yang rumit jadi sangat mudah dan smooth. Dengan service express Easybiz saya bisa mendirikan perusahaan dengan semua dokumen legalitas yang proper hanya dalam hitungan 4 hari. Terima kasih banyak untuk service yang luar biasa, komunikasi yang sangat responsive dan hasil yang memuaskan!',
      name: 'Andi Alam',
      position: 'Founder T.A.G Indonesia.',
      companyLogo: tag,
      photo: andi,
      companyLink: 'https://www.tag-id.com/',
    },
    {
      message: 'Easybiz prosesnya mudah dan efisien Recommended!',
      name: 'Ivan Nikolas Tambunan',
      position: 'Direktur Utama Pt Akseleran Grup Indonesia',
      companyLogo: akseleran,
      photo: ivan,
      companyLink: 'https://www.akseleran.co.id/',
    },
  ];
  const totalData = contents?.length || 0;
  const activeIdx = currentIndex;
  const activeItem = contents[activeIdx];

  return (
    <section
      css={css`
        ${revertColor ? tw`bg-black-700 dark:bg-black-100` : tw`bg-white dark:bg-black-200`}
      `}
      style={style}
      className={className}
    >
      <div tw="container">
        <div tw="lg:text-center mx-8 lg:mx-32 lg:text-center py-12">
          {withoutHeader ? (
            <div></div>
          ) : (
            <h2 tw="text-black-100 dark:text-white mb-14">
              Jadilah Bagian dari Klien Puas Easybiz
            </h2>
          )}
          {children}
          <div
            css={css`
              min-height: 430px;
              ${revertColor ? tw`bg-white` : tw`bg-black-700`}
            `}
            tw="text-left rounded-2xl relative mx-0 lg:mx-32 py-6 lg:py-12 px-12 2xl:px-24"
          >
            <div tw="absolute left-1 lg:left-4 h-full top-0 flex flex-col justify-center">
              <button
                tw="cursor-pointer bg-black-700 rounded-2xl border-0 py-2 px-3 lg:py-4 lg:px-5 focus:outline-none"
                onClick={() => left()}
                css={css`
                  ${!currentIndex || currentIndex < 1 ? tw`opacity-20` : ``}
                `}
                disabled={!currentIndex || currentIndex < 1}
              >
                <span
                  tw="text-black-300 text-h4 lg:text-h2"
                  className="icon icon-chevron-left"
                  aria-label="slide-left"
                ></span>
              </button>
            </div>
            <div tw="absolute right-1 lg:right-4 h-full top-0 flex flex-col justify-center">
              <button
                tw="cursor-pointer bg-black-700 rounded-2xl border-0 py-2 px-3 lg:py-4 lg:px-5 focus:outline-none"
                onClick={() => right()}
                css={css`
                  ${currentIndex > totalData - 2 ? tw`opacity-20` : ``}
                `}
                disabled={currentIndex > totalData - 2}
              >
                <span
                  tw="text-black-300 text-h4 lg:text-h2"
                  className="icon icon-chevron-right"
                  aria-label="slide-right"
                ></span>
              </button>
            </div>
            <div tw="hidden">
              {
                contents?.map((a, i) => {
                  return (
                    <div key={i + '-testimonials-hidden'}>
                      <h3 tw="text-h4 mb-3 font-extrabold font-gilroy">{a.name}</h3>
                    </div>
                  );
                })
              }
            </div>
            <div
              tw="flex"
              css={css`
                @media (max-width: 1023px) {
                  flex-wrap: wrap;
                }
              `}
            >
              <Quotes
                width="40px"
                height="32px"
                tw="absolute lg:static top-6 flex-none lg:mr-3"
                css={css`
                  left: 5px;
                  @media (max-width: 1024px) {
                    left: 15px;
                    height: 16px;
                    width: 20px;
                  }
                `}
              />
              <div
                css={css`
                  overflow: hidden;
                `}
              >
                <SwitchTransition mode="out-in">
                  <CSSTransition<undefined>
                    key={activeIdx}
                    classNames="slide"
                    addEndListener={(node: HTMLElement, done: () => void) => {
                      node.addEventListener('transitionend', done, false);
                    }}
                  >
                    <div
                      tw="flex"
                      css={css`
                        @media (max-width: 1024px) {
                          flex-wrap: wrap;
                        }
                      `}
                    >
                      <Img
                        alt={activeItem?.name}
                        tw="mr-4 mb-3 lg:mb-0 flex-none"
                        fluid={activeItem?.photo as FluidObject}
                        css={css`
                          height: 126px;
                          width: 126px;
                          @media (max-width: 1024px) {
                            width: 80px;
                            height: 80px;
                          }
                        `}
                      />
                      <div>
                        <div
                          tw="text-h4 mb-3 font-extrabold font-gilroy"
                          css={css`
                            letter-spacing: 0.02em;
                            .message-clamp {
                              button {
                                ${tw`text-blue-lapis cursor-pointer p-0 border-0 focus:outline-none bg-transparent`}
                              }
                            }
                          `}
                        >
                          <ClampLines
                            text={activeItem?.message}
                            id="really-unique-id"
                            lines={4}
                            ellipsis="..."
                            moreText="read more"
                            lessText="read less"
                            innerElement="div"
                            className="message-clamp"
                          ></ClampLines>
                        </div>
                        {activeItem?.companyLink ? (
                          <a
                            tw="text-black-100 cursor-pointer no-underline"
                            href={activeItem?.companyLink}
                            target="__blank"
                          >
                            <p tw="text-h4 mb-3 font-extrabold font-gilroy">{activeItem?.name}</p>
                          </a>
                        ) : (
                          <p tw="text-h4 mb-3 font-extrabold">{activeItem?.name}</p>
                        )}
                        <p tw="text-b1 m-0">{activeItem?.position}</p>
                        {activeItem?.companyLogo ? (
                          <a
                            href={activeItem?.companyLink}
                            target="__blank"
                            aria-label={'kunjungi ' + activeItem?.companyLink}
                          >
                            <Img
                              alt={activeItem?.position}
                              tw="mt-2"
                              fluid={activeItem?.companyLogo as FluidObject}
                              css={css`
                                height: 115px;
                                width: 115px;
                                @media (max-width: 1024px) {
                                  width: 75px;
                                  height: 75px;
                                }
                              `}
                            />
                          </a>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </CSSTransition>
                </SwitchTransition>
              </div>
            </div>
            <div tw="w-full mt-8 absolute bottom-4 left-0">
              <div tw="flex justify-center">
                {[...Array(totalData)].map((_, i) => (
                  <Ellipse
                    key={i}
                    width="10px"
                    height="10px"
                    fill={i === (currentIndex || 0) ? '#2B50CB' : '#DADADA'}
                    tw="cursor-pointer mx-1"
                    onClick={() => {
                      setCurrentIndex(i);
                      clearTimeout(timeout);
                    }}
                  ></Ellipse>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const testimonialsQuery = graphql`
  query getTestimonialsData {
    andika: file(relativePath: { eq: "images/andika.png" }) {
      childrenImageSharp {
        fluid(maxHeight: 129, maxWidth: 129) {
          ...FileImageSharpFluid
        }
      }
    }
    mignonne: file(relativePath: { eq: "images/mignonne.png" }) {
      childrenImageSharp {
        fluid(maxHeight: 129, maxWidth: 129) {
          ...FileImageSharpFluid
        }
      }
    }
    andi: file(relativePath: { eq: "images/andi.png" }) {
      childrenImageSharp {
        fluid(maxHeight: 129) {
          ...FileImageSharpFluid
        }
      }
    }
    ivan: file(relativePath: { eq: "images/ivan.png" }) {
      childrenImageSharp {
        fluid(maxHeight: 129, maxWidth: 129) {
          ...FileImageSharpFluid
        }
      }
    }
    sejutaCita: file(relativePath: { eq: "images/logo-sejutacita.png" }) {
      childrenImageSharp {
        fluid(maxWidth: 120) {
          ...FileImageSharpFluid
        }
      }
    }
    akseleran: file(relativePath: { eq: "images/logo-akseleran.png" }) {
      childrenImageSharp {
        fluid(maxWidth: 120) {
          ...FileImageSharpFluid
        }
      }
    }
    dauresia: file(relativePath: { eq: "images/logo-dauresia.png" }) {
      childrenImageSharp {
        fluid(maxWidth: 120) {
          ...FileImageSharpFluid
        }
      }
    }
    tag: file(relativePath: { eq: "images/logo-tag.png" }) {
      childrenImageSharp {
        fluid(maxWidth: 120) {
          ...FileImageSharpFluid
        }
      }
    }
  }
`;

interface TestimonialsProps {
  withoutHeader?: boolean;
  style?: React.CSSProperties;
  className?: string;
  revertColor?: boolean;
}

const Testimonials: React.FC<TestimonialsProps> = ({
  withoutHeader,
  children,
  style,
  className,
  revertColor,
}) => {
  return (
    <StaticQuery
      query={testimonialsQuery}
      render={data => (
        <TestimonialsComp
          data={data}
          withoutHeader={withoutHeader}
          style={style}
          className={className}
          children={children}
          revertColor={revertColor}
        />
      )}
    />
  );
};

export default Testimonials;
